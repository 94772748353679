import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '~/components/Layout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Socials from '../components/global/Socials';
import { Link } from 'gatsby';

import { navigate } from 'gatsby';
import { ChevronBlk, DocIcon, Twitter, Facebook, Linkedin } from '../components/elements/Icon';

import ArticleRenderer from '../components/article/ArticleRenderer';
import { FlexArticleRelatedResources } from '../components/article/FlexArticleRelatedPosts';
import WikiCta from '../components/landing/wiki/WikiCta';

const SingleWiki = ({ data: { wpWikiPost, wpPage, wp } }) => {
  const article = wpWikiPost?.postTypeWiki?.article;
  const relatedPosts = wpWikiPost?.postTypeWiki?.relatedPostsAllPosts;
  const cta = wpWikiPost?.postTypeWiki?.cta;

  return (
    <Layout wp={wp} darkHeader={true}>
      <Seo post={wpWikiPost} title={wpWikiPost?.title} />
      <div className="bg-offWhite">
        <SingleMasthead masthead={wpWikiPost?.postTypeWiki?.masthead} />
        <ArticleRenderer content={article?.articleBlocks} prefix={'WikiPost_Posttypewiki_Article_ArticleBlocks_'} />
        {relatedPosts.length > 0 && (
          <>
            <hr className="border-t border-borderGrey2 w-[96%] max-w-[892px] mx-auto mb-12 md:mb-[74px]" />
            <FlexArticleRelatedResources relatedItem={relatedPosts} />{' '}
          </>
        )}
        <WikiCta cta={cta} />
      </div>
    </Layout>
  );
};

export default SingleWiki;

export const SingleMasthead = ({ masthead }) => {
  return (
    <section>
      <div className="container pt-24 md:pt-44 lg:pt-[180px] mb-12 md:mb-20">
        <div>
          <Link to={`/repoolpedia/`} className="inline-flex space-x-2 items-center t-15">
            <ChevronBlk />
            <span>Back to previous page</span>
          </Link>
        </div>
        <div className="text-center mt-10 md:mt-16 lg:mt-[75px] mb-8 lg:mb-[40px]">
          <h6 className="text-blue uppercase font-bold !font-body t-14 tracking-widest mb-6 md:mb-[35px]">
            {masthead.subheading}
          </h6>
          <h1 className="t-52">{masthead.heading}</h1>
        </div>
        <div className="relative sm:w-[90%] max-w-[738px] mx-auto mb-8 md:mb-[55px] border border-borderGrey rounded-[6px] bg-white p-6 md:p-[45px] md:pr-[66px]">
          <h6 className="text-blue uppercase font-bold !font-body t-14 tracking-widest mb-6 md:mb-[35px]">
            Quick Definition
          </h6>
          <p className="t-20 leading-[1.4]" dangerouslySetInnerHTML={{ __html: masthead.quickDefinition }} />
          <LetterIcon className={'absolute bottom-[30px] right-0'} />
        </div>
        <SocialShare />
        <hr className="border-t border-borderGrey2 max-w-[892px] mx-auto" />
      </div>
    </section>
  );
};

export const SocialShare = ({}) => {
  return (
    <div className="max-w-[232px] mx-auto mb-8 flex justify-between items-center space-x-2 gap-x-6">
      <Socials altFill="fill-[#2554fd]" altFillHover="hover:fill-[#12C0C0]" />
    </div>
  );
};

export const LetterIcon = ({ className }) => (
  <div className={className}>
    <svg width="217" height="155" viewBox="0 0 217 155" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1164_1652)">
        <g opacity="0.03">
          <path
            d="M72.5953 31.0573C73.0116 30.0137 73.4278 29.5962 73.6359 29.5962C74.0522 29.5962 74.4684 30.2224 74.8847 31.266L96.946 89.0846C97.1542 89.7108 97.3623 90.337 97.3623 90.5457C97.3623 91.1719 96.946 91.5894 96.1135 91.5894H49.0771C48.0365 91.5894 47.6202 91.1719 47.6202 90.337C47.6202 89.9195 47.8283 89.5021 48.0365 88.8759L72.5953 31.0573ZM141.693 140.224C138.779 137.302 135.449 131.248 131.911 121.855L86.5397 3.08732C85.9154 1.6262 84.8747 1 83.4178 1H76.7578C75.3009 1 74.2603 1.6262 73.6359 3.08732L22.6451 118.098C19.9395 124.36 17.2338 129.578 14.3201 133.962C7.86815 143.772 1 144.398 1 148.156C1 150.452 3.08126 151.704 7.24378 151.704H36.1733C40.1277 151.704 42.2089 150.452 42.2089 147.738C42.2089 143.355 32.6351 144.19 32.6351 133.753C32.6351 128.535 35.5489 118.724 41.5845 104.322C42.2089 103.07 43.0414 102.443 44.4983 102.443H99.0273C101.109 102.443 102.357 103.278 102.982 104.739L110.058 123.108C112.555 129.787 113.804 134.379 113.804 137.093C113.804 139.806 112.347 142.102 109.434 143.563C106.52 145.025 105.063 146.486 105.063 147.947C105.063 150.452 106.728 151.704 110.266 151.704H144.815C148.769 151.704 150.643 150.452 150.643 147.947C150.643 144.398 146.272 144.816 141.693 140.224Z"
            fill="#130145"
          />
          <path
            d="M221.116 96.3902C221.116 110.793 219.659 121.02 216.745 127.074C211.959 136.884 202.801 141.894 194.268 141.894C183.653 141.894 177.618 135.005 177.618 126.03C177.618 119.142 181.156 113.297 188.024 108.705C191.354 106.409 196.765 103.696 204.05 100.565C211.542 97.4338 216.537 94.7203 219.035 92.8418C219.659 92.2156 220.284 92.0068 220.492 92.0068C220.908 92.0068 221.116 92.2156 221.116 92.8418V96.3902ZM247.964 138.971C243.594 138.971 241.304 135.423 241.304 128.326C241.304 122.69 241.304 114.132 241.512 102.235C241.721 90.337 241.929 81.5703 241.929 76.1432C241.929 54.6439 227.36 40.4502 201.552 40.4502C194.684 40.4502 188.024 41.2851 181.156 43.1637C174.496 44.8336 169.917 47.3383 167.212 50.678C164.922 53.6003 163.673 58.6098 163.673 65.9154C163.673 72.8035 165.546 76.1432 169.501 76.1432C171.79 76.1432 173.455 74.6821 174.288 73.6385C175.12 72.5948 177.41 68.4202 178.867 66.5416C183.237 60.4884 185.735 57.1487 192.187 53.809C195.517 51.9304 199.055 51.0955 203.009 51.0955C214.04 51.0955 221.116 57.9836 221.116 68.2115C221.116 76.9782 215.289 84.075 203.634 89.2933C201.344 90.337 195.933 92.4243 187.4 95.5553C178.867 98.6862 172.623 101.608 168.46 104.322C159.927 109.958 155.556 117.472 155.556 127.074C155.556 142.102 168.668 154 188.024 154C201.344 154 214.04 148.156 221.324 137.302C221.74 136.675 221.949 136.467 222.157 136.467C222.365 136.467 222.573 136.884 222.573 137.51C223.614 148.99 230.69 154 238.391 154C242.553 154 246.091 152.748 249.213 150.034C252.335 147.321 254 144.607 254 142.311C254 140.015 252.751 138.971 250.462 138.971H247.964Z"
            fill="#130145"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1164_1652">
          <rect width="217" height="155" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const pageQuery = graphql`
  query Wiki($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpWikiPost(id: { eq: $id }) {
      ...GeneratedWpWikiPost
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      postTypeWiki {
        previewgroup {
          previewDescription
        }
        masthead {
          subheading
          heading
          quickDefinition
        }
        article {
          articleBlocks {
            ... on WpWikiPost_Posttypewiki_Article_ArticleBlocks_Text {
              fieldGroupName
              heading
              text
            }
            ... on WpWikiPost_Posttypewiki_Article_ArticleBlocks_Image {
              fieldGroupName
              image {
                ...Image
              }
            }
            ... on WpWikiPost_Posttypewiki_Article_ArticleBlocks_Quote {
              fieldGroupName
              quote
              addContext
              by {
                name
                role
              }
            }
            ... on WpWikiPost_Posttypewiki_Article_ArticleBlocks_Bullets {
              fieldGroupName
              heading
              bullets {
                text
              }
            }
          }
        }
        relatedPostsAllPosts {
          relatedPosts {
            postType
            wikiPost {
              ... on WpWikiPost {
                id
                uri
                title
                postTypeWiki {
                  previewgroup {
                    previewDescription
                  }
                }
                featuredImage {
                  node {
                    altText
                    publicUrl
                    sourceUrl
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
            blogPost {
              ... on WpPost {
                id
                title
                uri
                postTypeSingle {
                  previewgroup {
                    previewDescription
                  }
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                    publicUrl
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
            marketPost {
              ... on WpMarketCommentaryPost {
                id
                title
                uri
                postTypeSingle {
                  previewgroup {
                    previewDescription
                  }
                }
                featuredImage {
                  node {
                    altText
                    publicUrl
                    sourceUrl
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
        cta {
          customCta
          heading
          button {
            url
            title
            target
          }
        }
      }
    }
  }
`;
